import { Controller } from "stimulus"
import $ from 'jquery'
import Rails from '@rails/ujs'
import Quill from "quill"
import { ImageDrop } from 'quill-image-drop-module'
import ImageResize from 'quill-image-resize-module'
import debounce from 'lodash/debounce'
import hljs from 'highlight.js'
import consumer from '../channels/consumer'
import ID from '../id'

export default class extends Controller {
  static targets = [ "editor", "title", "content", "consumer" ]

  connect() {
    this.initChannel()
    this.initQuill()

    $(this.titleTarget).on('keyup', debounce(() => this.autosave(), 1000, { maxWait: 3000}))
    $(this.categoryTarget).on('change', ()=> { this.autosave() })
  }

  initChannel() {
    $(this.consumerTarget).val(ID())
    consumer.subscriptions.create({ channel: "UpdateChannel", model: this.data.get('model'), id: this.data.get('id') }, {
      connected() {
        //console.log("Connected to channel")
      },
      received(data) {
        if (data.consumer !== $('#consumer').val()) { // we cannot access the targets here so get the consumer with id
          //console.log("model updated, need to reload")
          //console.log(data)
          Turbolinks.visit(location.toString())
        } else {
          //console.log("no need for update as we changed the data")
        }
      },
    });
  }

  initQuill() {
    Quill.register('modules/imageResize', ImageResize)
    Quill.register('modules/imageDrop', ImageDrop)

    this.quill = new Quill(this.editorTarget, {
      placeholder: 'Notiz erfassen...',
      modules: {
        clipboard: {
          matchVisual: false,
        },
        syntax: {
          highlight: text => hljs.highlightAuto(text).value
        },
        toolbar: {
          container: '#noteForm-toolbar'
        }
      },
      theme: 'snow'
    });

    this.quill.on('text-change', () => { this.updateHiddenContent() })
    this.quill.on('text-change', debounce(() => this.autosave(), 1000, { maxWait: 3000}))
  }

  updateHiddenContent() {
    let html = $(this.editorTarget)[0].children[0].innerHTML
    $(this.contentTarget).val(html)
  }

  autosave() {
    Rails.fire(this.element, 'submit', { autoupdate: true })
  }
}
