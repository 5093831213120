export const events = {
  CATEGORY_CHANGED: "category:changed"
}

const subscribe = (eventName, callback) => {
  if (!Object.values(events).includes(eventName)) {
    throw `Cannot subscribe to undefined event ${eventName}`
  }
  if (!callback) {
    throw `Cannot subscribe to ${eventName} with an undefined callback`
  }
  window.addEventListener(eventName, callback)
  return () => window.removeEventListener(eventName, callback)
}

const publish = (eventName, data = {}) => {
  if (!Object.values(events).includes(eventName)) {
    throw `Cannot publish to undefined event ${eventName}`
  }

  data.sent_at = new Date().toISOString()
  const event = new CustomEvent(eventName, {
    detail: Object.assign({}, data),
  })
  window.dispatchEvent(event)
}

export const bus = { publish: publish, subscribe: subscribe }