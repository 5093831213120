import { Controller } from "stimulus"
import $ from 'jquery'
import Isotope from "isotope-layout"

export default class extends Controller {

  initialize() {
    this.isotope = new Isotope(this.element, {
      itemSelector: '.note-list-item', // todo make configurable
      layoutMode: 'vertical',
      getSortData: {
        favorite: '[data-favorite]',
        name: '.note-list-title' // todo make configurable
      },
      sortBy: [ 'favorite', 'name' ],
      sortAscending: { favorite: false, name: true }
    });
    document.addEventListener('toggleNavigation', () => this.updateLayout(), false);
  }

  toggleFavorite(event) {
    let item = event.currentTarget.parentElement;
    let favorite = $(item).data('favorite');
    $(item).data('favorite', !favorite);
    this.updateLayout()
  }

  updateLayout() {
    this.isotope.updateSortData()
    this.isotope.arrange()
  }
}
