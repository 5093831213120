import { Controller } from "stimulus"
import $ from 'jquery'
import axios from "axios"

export default class extends Controller {
  static targets = [ "title", "subtitle" ]

  connect() {
    this.updateItem()
  }

  updateItem() {
    axios.get(this.data.get("url")).then((response) => {
      if (response.data.status === 'loaded') {
        this.element.outerHTML = response.data.card
      } else {
        $(this.titleTarget).html(response.data.title)
        if (this.hasSubtitleTarget) {
          $(this.subtitleTarget).html(response.data.subtitle)
        }
        setTimeout(() => this.updateItem(), 1000)
      }
    })
  }
}