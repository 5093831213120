import $ from 'jquery'
import tippy from 'tippy.js'
//import 'tippy.js/themes/light-border.css'

$( document ).on('turbolinks:load', function() {
  tippy.setDefaults({
    animation: 'fade',
    arrow: true,
//  theme: 'light-border'
  })
  tippy('[data-tippy-content]')
})