import { Controller } from "stimulus"
import Huebee from "huebee"

export default class extends Controller {

  initialize() {
    let huebee = new Huebee(this.element, {
      setBGColor: true,
      saturations: 1,
      customColors: [ '#C25', '#E62', '#EA0', '#19F', '#333' ],
      staticOpen: true
    });
  }
}
