import { Controller } from "stimulus"
import $ from 'jquery'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ "archivelink", "popup", "newarchive" ]

  connect() {
    $(this.archivelinkTarget).on('click', (event) => {
      $(this.popupTarget).toggle()
      this.newarchiveTarget.focus()
    })

    $(document).on("keyup", (event) => {
      let key = event.which || event.keyCode;
      if (key === 27) { // 27 is escape
        $(this.popupTarget).hide()
      }
    })

    $(document).on("mouseup", (event) => {
      let container = $(this.popupTarget)

      // if the target of the click isn't the container nor a descendant of the container
      if (!container.is(event.target) && container.has(event.target).length === 0) {
        container.hide();
      }
    })
  }

  createArchive(event) {
    Rails.ajax({
      url: "/archives",
      type: "POST",
      data: "name=" + this.newarchiveTarget.value,
      success: (data) => this.reloadSite()
    })
  }

  createArchiveOnEnter(event) {
    if (event.keyCode === 13) {
      this.createArchive(event)
    }
  }

  selectArchive(event) {
    if ($(event.currentTarget).attr('data-selected') === "true") {
      $(this.popupTarget).hide()
    } else {
      Rails.ajax({
        url: "/archives/" + $(event.currentTarget).attr('data-id') + "/select",
        type: "POST",
        success: (data) => this.reloadSite()
      })
    }
  }

  deleteArchive(event) {
    let archiveId = $(event.currentTarget).attr('data-id')
    Rails.ajax({
      url: "/archives/" + $(event.currentTarget).attr('data-id'),
      type: "DELETE",
      success: (data) => {
        if (archiveId === this.selectedArchiveId()) {
          this.reloadSite()
        } else {
          $(this.element).find(`.archive-select-bereich[data-id='${archiveId}']`).remove()
        }
      }
    })
  }

  renameArchiveStart(event) {
    let archiveId = $(event.currentTarget).attr('data-id')
    $(this.element).find(`input[data-id='${archiveId}']`).val($.trim(this.archiveTitle(archiveId).text()))
    $(this.element).find(`input[data-id='${archiveId}']`).show()
    $(this.element).find(`input[data-id='${archiveId}']`).focus()
    $(this.element).find(`.rename[data-id='${archiveId}']`).show()
    $(this.element).find(`.renameStart[data-id='${archiveId}']`).hide()
    $(this.element).find(`.archive-select-bereich-title[data-id='${archiveId}']`).hide()

  }

  renameArchive(event) {
    let archiveId = $(event.currentTarget).attr('data-id')
    let inputField = $(this.element).find(`input[data-id='${archiveId}']`)
    inputField.hide()
    $(this.element).find(`.rename[data-id='${archiveId}']`).hide()
    $(this.element).find(`.renameStart[data-id='${archiveId}']`).show()
    this.archiveTitle(archiveId).text($.trim(inputField.val()))
    this.archiveTitle(archiveId).show()
    Rails.ajax({
      url: "/archives/" + archiveId,
      type: "PUT",
      data: "name=" + inputField.val()
    })
    if (archiveId === this.selectedArchiveId()) {
      $(this.archivelinkTarget).text($.trim(inputField.val()))
    }
  }

  renameArchiveOnEnter(event) {
    if (event.keyCode === 13) {
      this.renameArchive(event)
    }
  }

  reloadSite() {
    Turbolinks.visit('/', { action: 'replace' })
  }

  archiveTitle(archiveId) {
    return $(this.element).find(`.archive-select-bereich-title[data-id='${archiveId}']`)
  }

  selectedArchiveId() {
    return $(this.element).find(".archive-select-bereich-title[data-selected='true']").attr('data-id')
  }
}