import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  connect() {
    this.inputTarget.select()
    this.inputTarget.focus()
  }

  search() {
    console.log("Seach for: " + this.inputTarget.value + "on: " + this.data.get("url"))
    Turbolinks.visit(this.data.get("url") + "?q=" + this.inputTarget.value)
  }
}