export const navigate = {
  visit: newUrl => {
    Turbolinks.visit(newUrl)
  },
  nativeVisit: newUrl => {
    window.location.assign(newUrl)
  },
  reload: () => {
    Turbolinks.visit(window.location)
  },
  nativeReload: () => {
    window.location.assign(window.location)
  },
  updatePageUrl: newUrl => {
    Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(
        newUrl,
        Turbolinks.uuid()
    )
  }
}