import { ApplicationController, events } from "./application_controller"
import $ from 'jquery'
import {navigate} from "../helpers/navigate";
import Rails from "@rails/ujs";

export default class extends ApplicationController {
  static targets = [ "newButton", "createBox", "input" ]

  connect() {
    $(this.createBoxTarget).hide()
    $(this.newButtonTarget).show()
  }

  choose(event) {
    event.preventDefault()
    let categoryId = $(event.target).data("categoryId")
    if (categoryId === undefined) {
      categoryId = null
    }
    //console.log("Change category to: " + categoryId)
    this.publish(events.CATEGORY_CHANGED, { id: categoryId })
  }

  newCategory(event) {
    $(this.newButtonTarget).hide()
    $(this.inputTarget).val("")
    $(this.createBoxTarget).show()
    this.inputTarget.focus()
  }

  createCategory(event) {
    //console.log("Create new category: " + $(this.inputTarget).val())
    let request = {
      url: this.data.get("create-url"),
      type: "POST",
      data: "name=" + $(this.inputTarget).val(),
      success: (data) => navigate.reload()
    }
    Rails.ajax(request)
  }

  createCategoryOnEnter(event) {
    if (event.keyCode === 13) {
      this.createCategory(event)
    }
  }

  cancel(event) {
    $(this.createBoxTarget).hide()
    $(this.newButtonTarget).show()
  }
}
