import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {
  static targets = [ "mainnav", "link" ]

  initialize() {
    this.toggleIconVisibility()
  }

  toggleNavigation(event) {
    event.preventDefault()
    $(this.mainnavTarget).toggleClass('open')
    this.toggleIconVisibility()
    $('.l-main').toggleClass('open')
    $.get($(this.linkTarget).attr('href'))
    setTimeout(() => this.fireToggleNavigationEvent(), 300)
  }

  toggleIconVisibility() {
    if ($(this.mainnavTarget).hasClass('open')) {
      $(this.linkTarget).find('.fa-angle-double-right').hide()
      $(this.linkTarget).find('.fa-angle-double-left').show()
    } else {
      $(this.linkTarget).find('.fa-angle-double-right').show()
      $(this.linkTarget).find('.fa-angle-double-left').hide()
    }
  }

  fireToggleNavigationEvent() {
    document.dispatchEvent(new CustomEvent('toggleNavigation'));
  }
}
