import { Controller } from "stimulus"
import $ from 'jquery'
import Masonry from "masonry-layout"
export default class extends Controller {

  initialize() {
    this.updateLayout()
    setTimeout(() => this.updateLayout(), 1000)
    document.addEventListener('toggleNavigation', () => this.updateLayout(), false)
  }

  updateLayout() {
    let selector = $(this.element).data('selector') || 'categoryBlock';
    new Masonry(this.element, {
      itemSelector: '.' + selector,
      columnWidth: 10,
      gutter: 0
    });
  }
}

