import { Controller } from "stimulus"
import { bus, events as busEvents } from "../helpers/event_bus"

export const events = busEvents

export class ApplicationController extends Controller {
  initialize() {
    //console.log("ApplictionController.initialize()")
    this.subscriptions = []
  }

  publish(event_name, data) {
    //console.log("ApplictionController.publish(" + event_name + ")")
    bus.publish(event_name, data)
  }

  subscribe(event_name, callback) {
    //console.log("ApplictionController.subscribe(" + event_name + ")")
    let theCallback = callback
    const subscription = bus.subscribe(event_name, event => theCallback(event.detail))
    this.subscriptions.push(subscription)
  }

  unsubscribe() {
    //console.log("ApplicationController.unsubscribe()")
    this.subscriptions.forEach(unsub => unsub())
  }

  disconnect() {
    //console.log("ApplicationController.disconnect()")
    this.unsubscribe()
  }
}