import { ApplicationController, events } from "./application_controller"
import { navigate } from "../helpers/navigate"
import tippy from "tippy.js"
import 'tippy.js/themes/light-border.css'
import Rails from "@rails/ujs";
import $ from "jquery";

export default class extends ApplicationController {
  static targets = [ "trigger", "popup" ]

  connect() {
    this.popupTarget.style.display = 'block'
    tippy(this.triggerTarget, {
      content: this.popupTarget,
      arrow: true,
      theme: 'light-border',
      trigger: 'click',
      interactive: true,
      animation: 'shift-away',
      duration: [100, 100],
      placement: 'right'
    })
    this.subscribe(events.CATEGORY_CHANGED, data => this.updateCategory(data))

    // close popup on ESC
    $(document).on("keyup", (event) => {
      let key = event.which || event.keyCode;
      if (key === 27) { // 27 is escape
        this.triggerTarget._tippy.hide()
      }
    })
  }

  updateCategory(data) {
    let request = {
      url: this.data.get("update-url"),
      type: "POST",
      success: (data) => navigate.reload()
    }
    if (data.id) {
      request.data = "category_id=" + data.id
    }
    Rails.ajax(request)
    this.triggerTarget._tippy.hide()
  }
}